import React, { Children } from 'react';
import "./InfoContainer.css";

const InfoContainer = ({ isPizza, children }) => {
    return ( 
        <div className="info-container">
            {isPizza ? (
                <div className="gm-pizzadata-container">
                    <div className="gmdata">
                        <div className="firstcol">
                            <div className="pizzadata-title">
                                <h3 style={{fontWeight:'bold'}}>Mentes Pizzáink </h3>
                                <span> (csak 30cm-ben rendelhetők!)</span>
                            </div>
                            <div className="gm-inline">
                                <span>Gluténmentes:</span>
                                <span className="numberFont" style={{fontWeight:'bold', color:'var(--primary)'}}>+700FT</span>
                            </div>
                            <div className="gm-inline">
                                <span>Laktózmentes:</span>
                                <span className="numberFont" style={{fontWeight:'bold', color:'var(--primary)'}}>+700FT</span>
                            </div>
                        </div>
                        <div className="secondcol">
                            <div className="pizzadata-title">
                                <h3 style={{fontWeight:'bold'}}>Plusz feltétek </h3>
                                <div className="pizzadata-sizes">
                                    <span> 30cm</span>
                                    <span> 50cm</span>
                                </div>
                                
                            </div>
                            <div className="gm-inline">
                                <span>Sajt:</span>
                                <div className="pizzadata-prices">
                                    <span className="numberFont" style={{fontWeight:'bold', color:'var(--primary)'}}>+400FT</span>
                                    <span className="numberFont" style={{fontWeight:'bold', color:'var(--primary)'}}>+650FT</span>
                                </div>
                            </div>
                            <div className="gm-inline">
                                <span>Húsok:</span>
                                <div className="pizzadata-prices">
                                    <span className="numberFont" style={{fontWeight:'bold', color:'var(--primary)'}}>+500FT</span>
                                    <span className="numberFont" style={{fontWeight:'bold', color:'var(--primary)'}}>+700FT</span>
                                </div>
                            </div>
                            <div className="gm-inline">
                                <span>Zöldség:</span>
                                <div className="pizzadata-prices">
                                    <span className="numberFont" style={{fontWeight:'bold', color:'var(--primary)'}}>+300FT</span>
                                    <span className="numberFont" style={{fontWeight:'bold', color:'var(--primary)'}}>+500FT</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ):(
                <div className="centeredText">{children}</div>
            )}
        </div>
    );
}

export default InfoContainer;